





























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'ChemOleMiss116PL9_Q4',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [
          {
            chemicalName: 'Chemical Name',
            hazard: 'Hazard Identifier',
            hazardDescription: 'Hazard Description',
          },
          {
            chemicalName: '',
            hazard: '',
            hazardDescription: '',
          },
        ],
        ppeList: '',
      },
      attachments: [] as File[],
      columns: [
        {label: 'Chemical Name', component: STextarea, prop: 'chemicalName', width: '30%'},
        {label: 'Hazard', component: STextarea, prop: 'hazard', width: '20%'},
        {
          label: 'Hazard Description',
          component: STextarea,
          prop: 'hazardDescription',
          width: '50%',
        },
      ],
    };
  },
  methods: {},
  computed: {
    imageName() {
      return '/img/assignments/GHS_Symbols.png';
    },
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
